<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <b-card-text
          >Seleccione la especialidad en la que desea asignar su
          cita:</b-card-text
        >
      </b-col>
    </b-row>
    <b-row align-h="center">
      <div class="d-flex justify-content-center mb-3 mt-3">
        <b-icon
          v-if="loading"
          icon="arrow-repeat"
          animation="spin-reverse"
          font-scale="4"
        ></b-icon>
      </div>
    </b-row>
    <b-row v-if="items.length > 0">
      <b-col >
        <b-table
          :fields="fields"
          :items="items"
          small
          head-variant="dark"
          bordered
          :tbody-tr-class="rowClass"
          @row-clicked="seleccionar($event)"
        >
        </b-table>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col class="text-center">
        <!-- {{items}} -->
        <p v-if="!loading"><b>No se encontraron especialidades</b></p>
        <p v-else><b>Consultando especialidades...</b></p>
      </b-col>
    </b-row>
    <b-row v-if="!loading">
      <b-col>
        <b-button @click="anterior()" variant="dark" class="btn-steps"
          >Anterior</b-button
        >
        <b-button @click="siguiente()" variant="dark" class="btn-steps" v-show="items.length > 0"
          >Siguiente</b-button
        >
      </b-col>
    </b-row>
    <b-modal v-model="showModalListProcedimientos" ok-title="Volver" ok-variant="success" centered header-bg-variant="dark" header-text-variant="light" ok-only size="lg" title="Por favor seleccione el procedimiento que desea agendar:">
      <b-list-group>
        <b-list-group-item button v-for="item in $store.state.afiliado.cups" :key="item" @click="seleccionarProcedimiento(item)">
          <b-icon icon="arrow-right-circle-fill"></b-icon>
          {{item.nombre}}
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

  export default {
    props: ["docSelected"],
    data: () => {
      return {
        loading: false,
        fields: [{ key: "nombre", label: "Especialidades" }],
        items: [],
        espSelected: null,
        showModalListProcedimientos: false
      };
    },
    computed: {
      ...mapState(["afiliado", "especialidades", "cita"]),
    },
    mounted() {
         
      this.listarEspecialidades();
    },
    methods: {
      ...mapActions([
        "getEspecialidadesAfil",
        "setEspecialidadCita",
        "nextStep",
        "prevStep",
        "setCupCita"
      ]),
      listarEspecialidades() {
        this.loading = true;
        this.getEspecialidadesAfil()
          .then((rta) => {
            this.loading = false;
            this.items = this.especialidades.map((e) => {
              return {
                codigo: e.codespecialidad,
                nombre: e.especialidad == 'EXAMEN ODONTOLOGICO DE PRIMERA VEZ Y CONTROL' ? 'EXAMEN ODONTOLOGICO DE PRIMERA VEZ' : e.especialidad,
                origen: e.origen
              };
            });
            this.items.push({
              codigo: null,
              nombre: 'TOMA DE MUESTRAS LABORATORIO CLÍNICO EN SEDE',
              origen: null,
              link: "https://arciortiz818.github.io/control-aforo"
            })
            //Filtrar especialidades
            //V350 - Teleorientacion
            this.items = this.items.filter(e => e.codigo != 'ECO' && e.codigo != 'RYX' && e.codigo != 'V350')
            
          })
          .catch((error) => {
            this.$toastr.e("No fue posible consultar las especialidades.");
            this.loading = false;
            if (error.status == 401) {
              this.logout().then((rta) => {
                this.$router.replace("/login");
              });
            }
          });
      },
      rowClass(item, type) {
        if (!item || type !== "row") return;
        if ((item.codigo == this.cita.especialidad && item.codigo) || (item.nombre == this.cita.nomEspecialidad && item.nombre)) {
          return "row-selected cursor-pointer";
        } else {
          return "cursor-pointer";
        }
      },
      seleccionar(item) { 
        if(item.link){
          window.open(item.link);
          return;
        }
        if(item){
          this.setEspecialidadCita({ codigo: item.codigo, nombre: item.nombre, origen: item.origen });
          if(item.origen == 'DX'){
            //Si tiene mas de un procedimiento en ecografia
            let cantidadCups = this.$store.state.afiliado.cups.reduce((sum,value) => {              
              return value.codEspecialidad == this.$store.state.cita.especialidad ? sum + 1 : sum
            },0)
            if(cantidadCups > 1){
              //Se muestra lista de procedimientos autorizados para que seleccione 
              this.showModalListProcedimientos = true;
              //Si confirma el modal, se lleva al paso de la agenda              
            }else{
              this.setCupCita(this.$store.state.afiliado.cups[0])
            }
          }
                    
        }    
      },
      anterior(){
        this.setEspecialidadCita({ codigo: null, nombre: null, origen: null }); 
        this.setCupCita(null);
        this.prevStep();
      },
      siguiente(){
        if(!this.cita.nomEspecialidad){
          this.$toastr.e('Por favor seleccione una especialidad para poder continuar.');
          return;
        }
        console.log(this.cita.origen)
        if(!this.cita.cups && this.cita.origen != 'ST'){
          this.$toastr.e('Por favor seleccione un procedimiento para poder continuar.');
          return;
        }
        this.nextStep()
      },
      seleccionarProcedimiento(item){
        this.setCupCita(item);
        this.showModalListProcedimientos = false
        this.siguiente()
      },
    },
  };
</script>
